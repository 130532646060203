import zoid from 'zoid';

import { decodeObject, encodeObject } from 'utils/encoding';

export function getEnvFromUrl(url) {
  if (url.includes("payex-web-dev")) {
    const matches = url.match(/payex-web-dev-(.+?)\.flywire\.cc/);
    return matches ? matches[1] : "local";
  }

  if (url.includes("flywire.lol")) {
    return "staging";
  }

  if (url.includes("demo.flywire.com")) {
    return "demo";
  }

  if (url.includes("flywire.com")) {
    return "production";
  }

  return "local";
}

if (!window.flywire) {
  const embedded = zoid.create({
    tag: 'fw-payment',
    dimensions: {
      width: '100%',
      height: '800px',
    },
    url: config => {
      const urls = {
        demo: 'https://payment.demo.flywire.com/',
        local: 'http://localhost:5300/',
        app: 'http://payex/',
        production: 'https://payment.flywire.com/',
        staging: 'https://payment.flywire.lol',
      };

      const { props } = decodeObject(config);
      return urls[props.env] || `https://payex-web-dev-${props.env}.flywire.cc`;
    },
    defaultEnv: 'demo',
    scrolling: true,
    defaultLogLevel: 'error',
  });

  const embeddedAdapter = {
    Payment: {
      render: (config, selector) => {
        const encodedConfig = encodeObject(config);

        return embedded(encodedConfig).render(selector);
      },
      getEnvFromUrl: getEnvFromUrl
    },
  };

  window.flywire = embeddedAdapter;
  window.requester = 'payex';
}